import Title from "./Title";
import { list_bias } from "./data";
import Bias from "./Bias";

const Biais = () => {
  return (
    <section className="section services" id="biais">
      <Title title="Qu'est ce qu'un" subTitle="Biais Cognitif?" />

      <div className="section-center services-center">
        {list_bias.map((item) => {
          const { id, icon, title, text } = item;
          return <Bias key={id} icon={icon} title={title} text={text} />;
        })}
      </div>
    </section>
  );
};
export default Biais;
