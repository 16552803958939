import aboutImg from "../images/codex.jpg";
import Title from "./Title";

const About = () => {
  return (
    <section className="section" id="about">
      <Title title="Une brève histoire" subTitle="" />

      <div className="section-center about-center">
        <div className="about-img">
          <img src={aboutImg} className="about-photo" alt="awesome beach" />
        </div>
        <article className="about-info">
          <h3>Eclairer la prise de décision</h3>
          <p>
            Nous avons tous vu des prises de décisions irrationnelles ou qui
            vont à l'encontre de ce qui est le plus pertinent, que ce soit au
            niveau d'une équipe, un projet ou une organisation.
          </p>
          <p>
            En creusant, on se rend rapidement compte de schémas de pensée
            particuliers, agissant sur notre prise de décision, Ces schémas de
            pensée automatique et inconscient, peuvent avoir un impact
            significatif sur notre prise de décision; c'est les biais cognitifs.
          </p>
          <p>
            "Mind Maze: les biais cognitifs", un jeu qui invite les joueurs à
            explorer ces schémas de pensée, à les comprendre et à les utiliser
            de manière constructive.
          </p>
        </article>
      </div>
    </section>
  );
};
export default About;
