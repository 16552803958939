import Title from "./Title";
import { ressources } from "./data";
import Livre from "./Livre";
import Lien from "./Link";

const Ressources = () => {
  return (
    <section className="section services" id="ressources">
      <Title title="Ressources" subTitle="Livres, liens et partenaires" />

      <div className="section-center services-center" id="ressources-livres">
        {ressources.livres.map((livre) => {
          const { id, author, title, img, link } = livre;
          return (
            <Livre
              key={id}
              author={author}
              title={title}
              img={img}
              link={link}
            />
          );
        })}
        {ressources.liens.map((lien) => {
          const { id, title, img, link } = lien;
          return <Lien key={id} title={title} img={img} link={link} />;
        })}
        {ressources.partenaires.map((partenaire) => {
          const { id, title, subtitle, img, link } = partenaire;
          return (
            <Livre
              key={id}
              title={title}
              author={subtitle}
              img={img}
              link={link}
            />
          );
        })}
      </div>
    </section>
  );
};
export default Ressources;
