import carteBiais from "../images/mm-bias.png";
import carteExemple from "../images/mm-exemple.png";
import carteAttenuation from "../images/mm-attenuation.png";

import codexImg from "../images/codex.jpg";

import modeGroup from "../images/equipe.jpg";
import modeIndividuel from "../images/individuel.png";
import modePerso from "../images/mmModePerso.jpg";

import mmCyber from "../images/mm-sec.jpg";
import mmRh from "../images/mm-rh.jpg";
import mmLogo from "../images/mm-st.jpg";
import mmMark from "../images/mm-marketing.png";
import mmDevops from "../images/mm-devops.png";

export const pageLinks = [
  { id: 1, href: "#accueil", text: "accueil" },
  { id: 2, href: "#about", text: "Brève histoire" },
  { id: 3, href: "#biais", text: "biais cognitifs" },
  { id: 4, href: "#lejeu", text: "le jeu" },
  { id: 5, href: "#ressources", text: "ressources" },
];

export const socialLinks = [
  {
    id: 10,
    href: "https://www.linkedin.com/in/zakaria-hzami-95229324/",
    icon: "fab fa-linkedin",
  },
  {
    id: 20,
    href: "mailto:zakaria.hzami@mind-maze.fr",
    icon: "fa fa-envelope",
  },
];

export const list_bias = [
  {
    id: 400,
    icon: "fas fa-school fa-fw",
    title: "The Oxford Handbook of Thinking and Reasoning",
    text: "Les biais cognitifs sont des \"tendances à traiter l'information de manière non objective, conduisant à des erreurs systématiques dans la perception, la mémoire, l'attention et la pensée.\"",
  },
  {
    id: 300,
    icon: "fas fa-newspaper fa-fw",
    title: "Journal of Experimental Psychology",
    text: "Les biais cognitifs sont \"des distorsions systématiques dans le traitement de l'information, résultant en erreurs systématiques dans la perception, la mémoire, l'attention et la prise de décision.\"",
  },
  {
    id: 500,
    icon: "fas fa-newspaper fa-fw",
    title: '"Cognitive Bias" publié dans le journal Cortex',
    text: "Les biais cognitifs sont \"des schémas de traitement de l'information qui dévient de la logique formelle et qui peuvent conduire à des distorsions dans la perception, l'interprétation et la prise de décision.\"",
  },
  {
    id: 200,
    icon: "fas fa-newspaper fa-fw",
    title: "Journal of Management",
    text: 'Les biais cognitifs sont des "modèles de traitement de l\'information qui provoquent une déviation systématique des choix rationnels."',
  },
  {
    id: 100,
    icon: "fas fa-book fa-fw",
    title: '"Thinking, Fast and Slow" de Daniel Kahneman',
    text: "Les biais cognitifs sont des 'tendances systématiques à penser d'une certaine manière qui dévient des normes de jugement rationnel'.",
  },
];

export const variantes = [
  {
    id: 1000,
    image: mmLogo,
    title: "Mind Maze Standard",
    info: "Une expérience immersive qui vous dévoilera les biais cognitifs les plus intrigants et universels",
  },
  {
    id: 2000,
    image: mmCyber,
    title: "Mind Maze: Cybersecurity",
    info: "Mettre la lumière les biais cognitifs exploités dans l'ingénierie sociale pour renforcer votre résilience en ligne",
  },
  {
    id: 3000,
    image: mmRh,
    title: "Mind Maze: Esprit RH",
    info: "Guider les ressources humaines à travers les biais cognitifs pertinents pour des choix plus éclairés",
  },
  {
    id: 4000,
    image: mmDevops,
    title: "Mind Maze: DEVOPS",
    info: "Mettre la lumière les biais cognitifs à connaître pour devenir un bon ingénieur DEVOPS",
  },
  {
    id: 5000,
    image: mmMark,
    title: "Mind Maze: Marketing",
    info: "Comprendre comment les marketteurs exploitent les biais cognitifs pour rendre indispensable ce qui ne l'est pas",
  },
];

export const cartes = [
  {
    id: 10000,
    image: carteBiais,
    title: "Carte Biais",
    info: "Découvrez le mécanisme subtil du biais cognitif, une fenêtre fascinante sur la façon dont notre esprit interprète l'information",
  },
  {
    id: 20000,
    image: carteExemple,
    title: "Carte Exemple",
    info: "Plongez-vous dans des scénarios concrets où ce biais peut influencer les décisions, enrichissant votre compréhension pratique de son impact",
  },
  {
    id: 30000,
    image: carteAttenuation,
    title: "Carte Atténuation",
    info: "Explorez des stratégies astucieuses pour atténuer l'effet de ce biais, offrant des outils pratiques pour une prise de décision plus éclairée",
  },
];

export const jeu = [
  {
    id: 100000,
    title: "mode équipe",
    img: modeGroup,
    rules: [
      { id: 1000001, content: "Chaque équipe est composée de 5 joueurs" },
      {
        id: 1000002,
        content:
          "On expose toutes les cartes de manière à ce que tous les membres puissent lire toutes les cartes",
      },
      {
        id: 1000003,
        content:
          "On déroule l’échange et la discussion en commençant par la carte exemple ou la carte biais, et on assemble le trio biais/exemple/atténuation.",
      },
      {
        id: 1000004,
        content:
          "On consigne les trio formés sur le support fourni et comparaison avec la solution.",
      },
      {
        id: 1000005,
        content: "Partage et discussion générale autour d’une bonne pizza.",
      },
    ],
  },
  {
    id: 2000001,
    title: "mode individuel",
    img: modeIndividuel,
    rules: [
      {
        id: 20000001,
        content:
          "Les joueurs reçoivent un nombre égal de cartes de biais, d'exemples et d'atténuation.",
      },
      {
        id: 20000002,
        content:
          "Un joueur pioche une carte d'exemple et la lit à voix haute. Les autres joueurs doivent identifier le biais cognitif associé à cet exemple.",
      },
      {
        id: 20000003,
        content:
          "Le joueur qui identifie correctement le biais gagne la carte d'exemple. Si aucun joueur ne parvient à identifier le biais, la carte reste sur la table.",
      },
      {
        id: 20000004,
        content:
          "Les joueurs peuvent utiliser leurs cartes d'atténuation pour proposer des stratégies pour contrer les biais cognitifs. Les autres joueurs évaluent les propositions et votent pour la meilleure solution.",
      },
      {
        id: 20000005,
        content:
          "Le jeu se poursuit jusqu'à ce que toutes les cartes d'exemple aient été jouées. Le joueur avec le plus grand nombre de cartes gagnées est déclaré vainqueur.",
      },
    ],
  },
  {
    id: 3000000,
    title: "mode personnalisé",
    img: modePerso,
    rules: [
      {
        id: 30000001,
        content:
          "Identifier un parcours souhaité en fonction du contexte de son organisation.",
      },
      {
        id: 30000002,
        content:
          "Optionnel : Mixer les cartes de plusieurs variantes dans ce parcours.",
      },
      {
        id: 30000003,
        content:
          "Partager les cartes exemples de façon ciblée/prédéfinie sur les participants.",
      },
      {
        id: 30000004,
        content:
          "Lire à tour de rôle les cartes exemple et chaque joueur essayera de trouver le biais correspondant. Le joueur qui trouvera le biais gagnera la carte example.",
      },
      {
        id: 30000005,
        content:
          "Le jeu se poursuit jusqu'à ce que toutes les cartes d'exemple aient été jouées. Le joueur avec le plus grand nombre de cartes gagnées est déclaré vainqueur.",
      },
    ],
  },
];

export const ressources = {
  livres: [
    {
      id: 10000000,
      title:
        "Encyclopédie des biais cognitifs: Le guide ultime pour bien décider et arrêter de se planter",
      author: "Boussad Addad",
      img: "https://m.media-amazon.com/images/I/41kgdYSbDUL._SX331_BO1,204,203,200_.jpg",
      link: "https://www.amazon.fr/Encyclop%C3%A9die-biais-cognitifs-d%C3%A9cider-arr%C3%AAter/dp/B0BMSVRH5S/ref=sr_1_1?keywords=l+encyclopedie+des+biais+cognitifs&sr=8-1",
    },
    {
      id: 2000000,
      title:
        "Les pièges des biais cognitifs: Découvrez les 26 biais de l’esprit qui trompent votre cerveau au quotidien !",
      author: "Victor Lejeune",
      img: "https://m.media-amazon.com/images/I/41iJbE5YJHL._SX311_BO1,204,203,200_.jpg",
      link: "https://www.amazon.fr/pi%C3%A8ges-biais-cognitifs-D%C3%A9couvrez-quotidien/dp/B0BSY5F79G/ref=sr_1_3?keywords=l+encyclopedie+des+biais+cognitifs&sr=8-3https://www.amazon.fr/pi%C3%A8ges-biais-cognitifs-D%C3%A9couvrez-quotidien/dp/B0BSY5F79G/ref=sr_1_3?keywords=l+encyclopedie+des+biais+cognitifs&sr=8-3",
    },
    {
      id: 3000000,
      title:
        "L'empire de l'erreur: Biais cognitifs et société Broché – Livre grand format",
      author: "Gérald Bronner",
      img: "https://m.media-amazon.com/images/I/41LWi1-OQcL._SY291_BO1,204,203,200_QL40_ML2_.jpg",
      link: "https://www.amazon.fr/Lempire-lerreur-Biais-cognitifs-soci%C3%A9t%C3%A9/dp/2130842534/ref=sr_1_fkmr2_2?keywords=l+encyclopedie+des+biais+cognitifs&sr=8-2-fkmr2",
    },
  ],
  liens: [
    {
      id: 1000001,
      title: "Le codex des biais cognitifs",
      link: "https://upload.wikimedia.org/wikipedia/commons/1/16/The_Cognitive_Bias_Codex_%28French%29_-_John_Manoogian_III_%28jm3%29.svg",
      img: codexImg,
    },
    {
      id: 2000001,
      title: "Cognitive Biases",
      link: "https://thedecisionlab.com/biases",
      img: "https://thedecisionlab.com/_next/image?url=https%3A%2F%2Fimages.prismic.io%2Fthedecisionlab%2F9f3f384a-852c-4699-abf9-f79d147730d6_tdl-logo.png%3Fauto%3Dcompress%2Cformat&w=384&q=75",
    },
    /*{
      id: 30000001,
      title: "Common Types of Bias That Influence Thinking",
      link: "https://www.verywellmind.com/cognitive-biases-distort-thinking-2794763",
      img: "https://www.verywellmind.com/thmb/UM3aAE3o0mTAmjntkliaGtmfYwQ=/144x144/filters:no_upscale():max_bytes(150000):strip_icc():format(webp)/IMG_9791-89504ab694d54b66bbd72cb84ffb860e.jpg",
    },*/
  ],

  partenaires: [
    /*{
      id: 130000001,
      title: "DEVOTEAM Innovative Tech",
      subtitle:
        "accompagne les DSI dans leurs stratégies de modernisation de plateformes.",
      img: "https://imgs.search.brave.com/phS0rofzqQQqbkKaBxpg80Ue2QC2dnh952iYYV99yq8/rs:fit:860:0:0/g:ce/aHR0cHM6Ly9jZG4t/aW1hZ2VzLndlbGNv/bWV0b3RoZWp1bmds/ZS5jb20vMG1mVDNP/alhVZmY5VEhZQ21W/R3NNZEoyZVh6cU5H/MExWRjhaX2xLUFVt/WS9yczphdXRvOjQw/MDo6L3E6ODUvY3pN/Nkx5OTNkSFJxTFhC/eWIyUjFZM1JwYjI0/dmRYQnNiMkZrY3k5/dmNtZGhibWw2WVhS/cGIyNHZiRzluYnk4/MU1USTVMekUyTlRV/NE1DOHdaVEEyTnpS/aFpDMW1aV1ptTFRR/d1pHVXRPV1V3T0Mx/aVkyRTBOMkpoTVdZ/d056a3VjRzVu",
      link: "https://france.devoteam.com/expertises/innovative-tech/",
    },*/
  ],
};
