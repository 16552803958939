import Title from "./Title";
import { cartes, jeu, variantes } from "./data";
import Carte from "./Carte";
import Mode from "./Mode";

const Jeu = () => {
  return (
    <section className="section" id="lejeu">
      <Title title="Le Jeu : " subTitle="Cartes et règles" />

      <Title title="les variantes" subTitle="" />
      <div className="section-center featured-center">
        {variantes.map((variante) => {
          const { id } = variante;
          return <Carte key={id} {...variante} />;
        })}
      </div>

      <Title title="les cartes" subTitle="" />
      <div className="section-center featured-center">
        {cartes.map((carte) => {
          const { id } = carte;
          return <Carte key={id} {...carte} />;
        })}
      </div>
      <Title title="les règles de jeu" subTitle="" />

      {jeu.map((mode) => {
        const { id, title, img, rules } = mode;
        return <Mode id={id} title={title} img={img} rules={rules} />;
      })}
    </section>
  );
};

export default Jeu;
