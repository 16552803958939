const Carte = ({ title, date, image, info, location, duration, cost }) => {
  return (
    <article className="tour-card">
      <div className="tour-img-container">
        <img src={image} className="tour-img" alt={title} />
        {/*<p className="tour-date">{date}</p>*/}
      </div>
      <div className="tour-info">
        <div className="tour-title">
          <h4>{title}</h4>
        </div>
        <p>{info}</p>
      </div>
    </article>
  );
};

export default Carte;
