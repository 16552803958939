const Mode = ({ title, id, img, rules }) => {
  if (id % 2 === 1) {
    return (
      <div className="section-center about-center" id={id}>
        <div className="about-img">
          <img src={img} className="about-photo" alt="" />
        </div>
        <article className="about-info">
          <h3>{title}</h3>
          <ul>
            {rules.map((rule) => {
              const { id, content } = rule;
              return <li key={id}>{content}</li>;
            })}
          </ul>
        </article>
      </div>
    );
  }
  return (
    <div className="section-center about-center" id={id}>
      <article className="about-info">
        <h3>{title}</h3>
        <ul>
          {rules.map((rule) => {
            const { id, content } = rule;
            return <li key={id}>{content}</li>;
          })}
        </ul>
      </article>
      <div className="about-img">
        <img src={img} className="about-photo" alt="" />
      </div>
    </div>
  );
};

export default Mode;
