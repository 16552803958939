const Lien = ({ id, title, img, link }) => {
  return (
    <article className="tour-card" key={id}>
      <div className="tour-img-container">
        <a href={link} target="_blank" rel="noreferrer">
          <img src={img} className="tour-img" alt={title} />
        </a>
      </div>
      <div className="tour-info">
        <div className="tour-title">
          <h4>{title}</h4>
        </div>
      </div>
    </article>
  );
};

export default Lien;
