import Footer from "./components/Footer";
import Main from "./components/Main";
import Navbar from "./components/Navbar";
import Biais from "./components/Biais";
import Jeu from "./components/Jeu";
import About from "./components/About";
import Ressources from "./components/Ressources";

function App() {
  return (
    <>
      <Navbar />
      <Main />
      <About />
      <Biais />
      <Jeu />
      <Ressources />
      <Footer />
    </>
  );
}

export default App;
