const Main = () => {
  return (
    <section className="hero" id="accueil">
      <div className="hero-banner">
        <h1>
          <span className="principal-title">Mind Maze</span> :
          <span className="secondary-title"> Les biais cognitifs</span>
        </h1>
        <p className="third-title">
          Reconnaitre, comprendre et atténuer les biais cognitifs
        </p>
        {/* <a href="#tours" className="btn hero-btn">
          explore tours
        </a> */}
      </div>
    </section>
  );
};
export default Main;
